<template>
  <v-container fluid class="pa-6">
    <h3>{{ $t('subscriberLists') }}</h3>

    <p>{{ $t('subscriberListsIntro') }}</p>

    <v-row class="py-4" style="max-width: 1800px">
      <v-col
        v-for="(list, index) in lists"
        :key="index"
        class="d-flex"
        cols="12"
        sm="6"
        md="4"
        lg="3"
        xl="2"
      >
        <subscriber-list
          :name="list.name"
          :count="list.subscribersCount"
          class="flex-grow-1"
          @edit="edit(list)"
          @delete="promptDelete(list)"
        />
      </v-col>
    </v-row>

    <v-dialog v-model="showEdit" max-width="700px" persistent>
      <v-card>
        <v-card-title>
          {{ selectedSubscriberListId ? $t('editList') : $t('newList') }}
        </v-card-title>
        <v-card-text>
          <edit-subscriber-list
            :existing-list-id="selectedSubscriberListId"
            @cancel="closeEdit"
            @save="closeEdit"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-btn color="primary" @click="newList">
      {{ $t('newList') }}
    </v-btn>
    <confirm ref="confirm" />
  </v-container>
</template>

<script>
import SubscriberList from '@/views/SubscriberLists/SubscriberList';
import EditSubscriberList from '@/views/SubscriberLists/EditSubscriberList';
import Confirm from '@/components/dialog/Confirm.vue';

export default {
  name: 'SubscriberLists',

  components: {
    SubscriberList,
    EditSubscriberList,
    Confirm,
  },

  data: () => ({
    loading: false,
    selectedSubscriberListId: '',
    showEdit: false,
  }),

  computed: {
    lists() {
      return this.$store.state.user.subscriberLists;
    },
  },

  mounted() {
    this.getSubscriberLists();
  },

  methods: {
    async getSubscriberLists() {
      try {
        await this.$store.dispatch('user/getSubscriberLists');
      } catch (error) {
        if (!error.hasBeenHandled) {
          alert(error);
        }
      }
    },

    edit(list) {
      this.selectedSubscriberListId = list.id;
      this.showEdit = true;
    },
    async promptDelete(list) {
      const confirmed = await this.$refs.confirm.open(
        this.$t('deleteList'),
        this.$t('deleteListMsg', { listName: list.name }),
        this.$t('delete'),
        this.$t('cancel'),
        null,
        false,
      );

      if (confirmed) {
        await this.delete(list.id);
      }

      this.$refs.confirm.close();
    },
    async delete(listId) {
      try {
        await this.$store.dispatch('user/deleteSubscriberList', listId);
      } catch (error) {
        if (!error.hasBeenHandled) {
          alert(error);
        }
      }
    },
    newList() {
      this.selectedSubscriberListId = '';
      this.$store.commit('user/setCurrentSubscriberList', null);
      this.showEdit = true;
    },
    closeEdit() {
      this.selectedSubscriberListId = '';
      this.showEdit = false;
    },
  },
};
</script>
