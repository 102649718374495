<template>
  <div class="d-flex">
    <v-text-field
      v-model="countryCode"
      :label="$t('countryCode')"
      :rules="countryCodeRules"
      prefix="+"
      class="country-code"
      type="tel"
      :outlined="outlined"
      @input="onChange"
    />
    <v-text-field
      v-model="number"
      :label="$t('phoneNumber')"
      :rules="fullRules"
      type="tel"
      :outlined="outlined"
      @input="onChange"
    />
  </div>
</template>

<script>
import parsePhoneNumber from 'libphonenumber-js';
import { required } from '@/util/formRules';

const defaultCountryCode = '47';

export default {
  name: 'PhoneNumberField',

  props: {
    value: {
      type: String,
      default: `+${defaultCountryCode}`,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    countryCode: defaultCountryCode,
    number: '',
  }),

  computed: {
    full() {
      return `+${this.countryCode}${this.number}`;
    },

    countryCodeRules() {
      return [required(this.$t('countryCode'))];
    },

    fullRules() {
      return this.rules.map((rule) => () => rule(this.full));
    },
  },

  watch: {
    value: {
      handler() {
        this.updateFromValue();
      },
      deep: true,
    },
  },

  created() {
    this.updateFromValue();
  },

  methods: {
    updateFromValue() {
      let value = this.value;
      if (!value) {
        this.countryCode = defaultCountryCode;
        this.number = '';
        return;
      }

      value = value.trim();

      if (!value.startsWith('+')) {
        value = `+${value}`;
      }

      if (value === this.full) {
        return;
      }

      const parsedPhoneNumber = parsePhoneNumber(value);
      if (parsedPhoneNumber) {
        if (parsedPhoneNumber.countryCallingCode)
          this.countryCode = parsedPhoneNumber.countryCallingCode;
        if (parsedPhoneNumber.nationalNumber) this.number = parsedPhoneNumber.nationalNumber;
      }
    },

    onChange() {
      this.$emit('input', this.full);
    },
  },
};
</script>

<style scoped>
.country-code {
  max-width: 100px;
}
</style>
