<template>
  <div>
    <v-form ref="formInstance" @submit.prevent="save">
      <div v-if="loading">
        <v-skeleton-loader type="article, actions" />
      </div>
      <template v-else>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field v-model="form.name" :rules="listNameRules" :label="$t('name')" outlined />
          </v-col>
        </v-row>

        <p class="subtitle-2 mt-4">{{ $tc('subscriber', 2) }}</p>

        <v-row v-for="(subscriber, index) in form.subscribers" :key="index">
          <v-col cols="6">
            <v-text-field
              v-model="subscriber.name"
              :label="$t('subscriberName')"
              :rules="subscriberNameRules"
              outlined
            />
          </v-col>
          <v-col cols="5">
            <phone-number-field
              v-model="subscriber.msisdn"
              :rules="subscriberMsisdnRules"
              outlined
            />
          </v-col>
          <v-col cols="1">
            <v-btn
              :style="{ opacity: form.subscribers.length > 1 ? 1 : 0 }"
              icon
              @click="removeSubscriber(index)"
            >
              <v-icon>fa-times</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-btn icon @click="addSubscriber">
          <v-icon>fa-plus</v-icon>
        </v-btn>

        <div class="d-flex justify-end mb-n3 mx-n2">
          <v-btn :disabled="saving" @click="cancel">
            {{ $t('cancel') }}
          </v-btn>

          <v-btn
            :loading="saving"
            color="primary"
            :disabled="!hasSubscribers"
            type="submit"
            class="px-2 ml-2"
          >
            {{ $t('save') }}
          </v-btn>
        </div>
      </template>
    </v-form>
  </div>
</template>

<script>
import { maxLength, minLength, required, validPhoneNumber } from '@/util/formRules';
import PhoneNumberField from '@/components/inputs/PhoneNumberField.vue';

const emptySubscriber = () => ({
  name: '',
  msisdn: '',
});

const emptyForm = () => ({
  name: '',
  subscribers: [emptySubscriber()],
});

export default {
  name: 'EditSubscriberList',

  components: {
    PhoneNumberField,
  },

  props: {
    existingListId: {
      type: String,
      required: false,
      default: '',
    },
  },

  data: () => ({
    form: emptyForm(),

    loading: false,

    saving: false,
  }),

  computed: {
    hasSubscribers() {
      return this.form.subscribers.length > 0;
    },
    listNameRules() {
      const field = this.$t('name');
      return [required(field), minLength(field, 3), maxLength(field, 128)];
    },
    subscriberNameRules() {
      const field = this.$t('subscriberName');
      return [required(field), minLength(field, 3), maxLength(field, 50)];
    },
    subscriberMsisdnRules() {
      const field = this.$t('phoneNumber');
      return [
        required(field),
        validPhoneNumber(field),
        (value) =>
          this.form.subscribers.filter((subscriber) => subscriber.msisdn === value).length < 2 ||
          this.$t('form.field.uniqueSubscriberList', { field }),
      ];
    },
    existingSubscriberList() {
      return this.$store.state.user.currentSubscriberList;
    },
  },

  watch: {
    existingListId() {
      this.getExistingList();
    },
    existingSubscriberList() {
      if (this.existingSubscriberList) {
        this.form = {
          name: this.existingSubscriberList.name,
          subscribers: this.existingSubscriberList.subscribers,
        };
      } else {
        this.$refs.formInstance.resetValidation();
        this.form = emptyForm();
      }
    },
    form: {
      handler() {
        this.$refs.formInstance.resetValidation();
      },
      deep: true,
    },
  },

  created() {
    this.getExistingList();
  },

  methods: {
    async getExistingList() {
      const existingListId = this.existingListId;
      if (!existingListId || existingListId.length < 1) {
        this.form = emptyForm();
        return;
      }

      this.loading = true;

      try {
        await this.$store.dispatch('user/getSubscriberList', existingListId);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        if (!error.hasBeenHandled) {
          alert(error);
        }
      }
    },

    async save() {
      if (!this.hasSubscribers || !this.$refs.formInstance.validate()) {
        return;
      }

      this.saving = true;

      try {
        if (this.existingListId) {
          await this.$store.dispatch('user/updateSubscriberList', {
            id: this.existingListId,
            ...this.form,
          });
        } else {
          await this.$store.dispatch('user/createSubscriberList', this.form);
        }
        this.form = emptyForm();
        this.$refs.formInstance.resetValidation();
        this.$emit('save');
        this.saving = false;
      } catch (error) {
        this.saving = false;

        const errorDetails = error?.errorDetails;
        if (!errorDetails && !error.hasBeenHandled) {
          alert(error);
          return;
        }

        alert(errorDetails);
      }
    },

    cancel() {
      this.form = emptyForm();
      this.$refs.formInstance.resetValidation();
      this.$store.commit('user/setCurrentSubscriberList', null);
      this.$emit('cancel');
    },

    addSubscriber() {
      this.form.subscribers.push(emptySubscriber());
    },

    removeSubscriber(index) {
      this.form.subscribers.splice(index, 1);
    },
  },
};
</script>
