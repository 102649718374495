<template>
  <v-card color="#1d3e52" dark class="subscriber-list d-flex flex-column">
    <v-card-title>{{ name }}</v-card-title>
    <v-spacer />
    <v-card-text>
      <v-icon>fa-users</v-icon> <strong class="ml-2">{{ count }}</strong>
      {{ $tc('subscriber', count) }}
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn text @click="$emit('delete')">{{ $t('delete') }}</v-btn>
      <v-btn color="primary" @click="$emit('edit')">{{ $t('edit') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'SubscriberList',

  props: {
    name: {
      type: String,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.hide-btn {
  opacity: 0;
}
.subscriber-list:hover .hide-btn {
  opacity: 1;
}
</style>
